.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('./truck.png'); /* Add the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-box {
  background-color: #282c34; /* Change background color to match App-header */
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  color: black; /* Add this line to change text color inside login box */
}


body {
  margin: 0;
  padding: 0;
  background-image: url('./truck.png'); /* Add the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

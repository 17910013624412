body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url('./truck.png'); /* Add the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.login-box {
  background-color: #fff;
  color: black;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
}

h2 {
  margin: 0 0 20px;
  text-align: center;
  color: #fff;
}

.input-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
}

input {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  background-color: #fff;
  color: #000;
}

button {
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  width: 100%;
}

button:hover {
  background-color: #4cae4c;
}

body {
  margin: 0;
  padding: 0;
  background-image: url('./truck.png'); /* Add the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
